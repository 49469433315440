import MenuIcon from './menu-icon.svg';
import SearchIcon from './search-icon.png';
import LikeIcon from './like-icon.svg';
import LockIcon from './lock-icon.png';

import TelegramLogo from './telegram.png';
import TwitterLogo from './twitter.png';
import YoutubeLogo from './youtube.png';
import FacebookLogo from './facebook.png';
import TelegramYellowLogo from './telegram-yellow.png';
import TwitterYellowLogo from './twitter-yellow.png';
import YoutubeYellowLogo from './youtube-yellow.png';
import FacebookYellowLogo from './facebook-yellow.png';
import TelegramGrayLogo from './telegram-gray.png';
import TwitterGrayLogo from './twitter-gray.png';
import YoutubeGrayLogo from './youtube-gray.png';
import FacebookGrayLoggo from './facebook-gray.png';

import FoodCategory from './food.png';

import PiNetwork from './pi-network.png';
import PiBarterMall from './pi-barter-mall.png';
import IntroductionBackground from './about-bg.png';
import CategoryBackground from './category-bg.png';
import PhotosBackground from './photos-bg.png';
import SignInBackground from './background.png';
import PiMapBackground from './map-bg.png';
import PiMapImage from './map.png';
import PhoneImage from './phone.png';
import SignInImage from './sign-in.png';
import SignInTitleImage from './system.png';
import NewsBgImage from './news1.png';
import UserAvatar from './user.png';
import SubmitImage from './submit.png';
import LeftLastDisabledIcon from './left-last-disable.png';
import LeftLastIcon from './left-last.png';
import RightNextDisabledIcon from './right-next-disable.png';
import RightNextIcon from './right-next.png';

import Carousel1 from './carousel-1.jpg';
import Carousel2 from './carousel-2.jpg';
import Carousel3 from './carousel-3.jpg';
import Carousel4 from './carousel-4.jpg';
import Carousel5 from './carousel-5.jpg';
import Carousel6 from './carousel-6.jpg';
import Carousel7 from './carousel-7.jpg';
import Carousel8 from './carousel-8.jpg';

import AboutImage from './about.jpg'
import SlidePDF from './slides.pdf';
import WhitePaper from './whitepaper.pdf';

export {
    MenuIcon, SearchIcon, LikeIcon, LockIcon,
    FoodCategory,
    PiNetwork,
    PiBarterMall,
    SignInImage, SignInTitleImage, NewsBgImage,
    IntroductionBackground, CategoryBackground, PhotosBackground, SignInBackground, UserAvatar,
    PiMapBackground, PiMapImage, PhoneImage,
    SubmitImage,
    LeftLastDisabledIcon, LeftLastIcon, RightNextDisabledIcon, RightNextIcon,
    Carousel1, Carousel2, Carousel3, Carousel4, Carousel5, Carousel6, Carousel7, Carousel8,
    AboutImage,
    SlidePDF, WhitePaper
};

export const SharedGrayLogos = {
    facebook: FacebookGrayLoggo,
    telegram: TelegramGrayLogo,
    twitter: TwitterGrayLogo,
    youtube: YoutubeGrayLogo,
};
export const SharedYellowLogos = {
    facebook: FacebookYellowLogo,
    telegram: TelegramYellowLogo,
    twitter: TwitterYellowLogo,
    youtube: YoutubeYellowLogo,
};
export const SharedDefaultLogos = {
    facebook: FacebookLogo,
    telegram: TelegramLogo,
    twitter: TwitterLogo,
    youtube: YoutubeLogo,
};