import React, { useState, useEffect, useMemo } from 'react';
import classname from 'classnames';
import { Input, Select, Button, message, Pagination, Row, Col } from 'antd';
import _ from 'lodash';
import styles from './section.module.css';
import './product.css';
import { ProductItem } from '../commodity';
import { Provinces } from '../../constants/constants';
import { GetProductCategories, GetProducts } from '../../constants/apiUtils';
import { useLocation } from 'react-router-dom';
import { Link } from 'react-router-dom';

export function ProductsSection({ queryProductName }) {
    const [messageApi, contextHolder] = message.useMessage();
    const [queryName, setQueryName] = useState(null);
    const [selectedProvince, setSelectedProvince] = useState(null);
    const [queryMerchant, setQueryMerchant] = useState(null);
    const [queryCategory, setQueryCategory] = useState(null);
    const [page, setPage] = useState(1);
    const [size, setSize] = useState(12);
    const [categories, setCategories] = useState([]);
    const [products, setProducts] = useState([]);
    const [totalCount, setTotalCount] = useState(0);
    const location = useLocation();

    useEffect(() => {
        if (location.hash === '#products-section') {
            const element = document.getElementById('products-section');
            if (element) {
                element.scrollIntoView({ behavior: 'smooth' });
            }
        }
    }, [location]);

    useEffect(() => {
        GetProductCategories().then(response => {
            setCategories(response.data.map(ctg => ({
                ...ctg,
                value: ctg.name,
                label: ctg.name,
            })));
        }).catch(error => {
            console.log('GetProductCategories error', error);
            messageApi.error(error.message);
        });
        GetProducts({ page, size }).then(response => {
            if (_.isArray(response.data)) {
                setProducts(response.data);
                setTotalCount(response.total);
            } else {
                throw new Error("response is not array");
            }
        }).catch(error => {
            console.log('onsearch, GetProducts error', error);
            messageApi.error(error.message);
        });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        if (queryProductName) {
            setQueryName(queryProductName);
            console.log('aaa');
            GetProducts({ name: queryProductName, page, size }).then(response => {
                if (_.isArray(response.data)) {
                    setProducts(response.data);
                    setTotalCount(response.total);
                } else {
                    throw new Error("response is not array");
                }
            }).catch(error => {
                console.log('onsearch, GetProducts error', error);
                messageApi.error(error.message);
            });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [queryProductName]);

    const onSearch = async (overrideParams) => {
        console.log('onsearch', queryMerchant, queryName, selectedProvince, queryCategory, size, page, overrideParams);
        await GetProducts({
            name: queryName,
            province: selectedProvince,
            merchant: queryMerchant,
            category: queryCategory,
            page,
            size,
            ...overrideParams,
        }).then(response => {
            if (_.isArray(response.data)) {
                setProducts(response.data);
                setTotalCount(response.total);
            } else {
                throw new Error("response is not array");
            }
        }).catch(error => {
            console.log('onsearch, GetProducts error', error);
        });
    }

    const onChangePage = (newPage) => {
        setPage(newPage);
        onSearch({ page: newPage });
    };

    const productsCols = useMemo(() => {
        return (
            <>
                {products.length > 0 && products.map(prdt => (
                    <Col span={4} key={`product-list-${prdt.id}-${prdt.name}-col`}>
                        <Link to={`/merchant/${prdt.merchantId}`}>
                            <ProductItem
                                key={`product-list-${prdt.id}-${prdt.name}`}
                                {...prdt}
                            />
                        </Link>
                    </Col>
                ))}
            </>
        );
    }, [products]);

    return (
        <div id="products-section" className={styles.productsSection}>
            {contextHolder}
            <div className={styles.toolbar}>
                <div className={styles.productTitle}>商品展示</div>
                <div className={styles.queryGroup}>
                    <Input
                        className={classname(styles.queryItem)}
                        placeholder="输入商品名字"
                        size='large'
                        shape='round'
                        value={queryName}
                        onChange={(e) => { setQueryName(e.target.value); }}
                    />
                    <Input
                        className={classname(styles.queryItem)}
                        placeholder="输入商家名称"
                        size='large'
                        onChange={(e) => { setQueryMerchant(e.target.value); }}
                    />
                    <Select
                        allowClear
                        className={classname(styles.queryItem)}
                        options={Provinces}
                        size='large'
                        placeholder="选择省份"
                        onChange={(value) => { setSelectedProvince(value); }}
                    />
                    <Select
                        allowClear
                        className={classname(styles.queryItem)}
                        options={categories}
                        size='large'
                        placeholder="选择商品类别"
                        onChange={(value) => { setQueryCategory(value); }}
                    />
                    <Button className={styles.queryCommodityButton} size='large' shape='round' onClick={onSearch}>搜索</Button>
                </div>
            </div>
            <Row className={styles.productsGroup}>{productsCols}</Row>
            <Pagination
                showQuickJumper
                className={styles.productPagination}
                defaultCurrent={page}
                total={totalCount}
                pageSize={12}
                onChange={onChangePage}
            />
        </div>
    );
}