import React, { useEffect, useMemo, useState } from 'react';
import { Button, Input, message } from 'antd';
import _ from 'lodash';
import styles from './action.module.css';
import { LockIcon, SubmitImage, UserAvatar } from '../../assets';
import './action.css';
import { useNavigate } from 'react-router';
import { GetCaptcha, Login } from '../../constants/apiUtils';
import { ACCESS_TOKEN, API_BASE_URL } from '../../constants/url';


export function LoginAction() {
    const navigate = useNavigate();
    const [messageApi, contextHolder] = message.useMessage();
    const [enteredCode, setEnteredCode] = useState(null);
    const [captcha, setCaptcha] = useState(null);
    const [refreshId, setRefreshId] = useState(0);

    const onChangeCode = (e) => {
        setEnteredCode(e.target.value);
    }
    const onChangeCaptcha = (e) => {
        setCaptcha(e.target.value);
    };
    const login = () => {
        Login({ login_code: enteredCode, captcha }).then(response => {
            if (response && response.success) {
                navigate('/home');
            } else {
                message.error(response.message || '登录失败，请重试！');
            }
        }).catch(error => {
            console.error('Login error:', error);
            message.error(error.message || '登录出错，请重试！');
        });
    };

    const onClickCaptcha = () => {
        setRefreshId(pre => (pre + 1) % 1000007);
    };
    const captchaUrl = useMemo(() => `${API_BASE_URL}/captcha?${refreshId}`, [refreshId]);

    return (
        <div className={styles.loginAction}>
            {contextHolder}
            <img className={styles.avatar} src={UserAvatar} alt="" />
            <p className={styles.loginTitle}>会员登录</p>
            <div className={styles.codeContainer}>
                <Input
                    className={styles.inputBox}
                    prefix={<img src={LockIcon} className={styles.inputIcon} />}
                    placeholder="输入TOKEN"
                    value={enteredCode}
                    onChange={onChangeCode}
                    visible
                />
            </div>
            <div className={styles.captchaContainer}>
                <Input
                    className={styles.inputBox}
                    prefix={<img src={captchaUrl} className={styles.captchaImage} onClick={onClickCaptcha} />}
                    placeholder="输入验证码"
                    value={captcha}
                    onChange={onChangeCaptcha}
                    visible
                />
            </div>
            <div className={styles.submitButton}>
                <img className={styles.submitBgImg} src={SubmitImage} />
                <Button
                    shape='round'
                    className={styles.buttonContainer}
                    ghost
                    onClick={login}>确认</Button>
            </div>
        </div>
    );
}