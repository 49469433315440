import React from 'react';
import { PiMapImage } from '../../assets';
import styles from './section.module.css';

export function PiMapSection() {
    return (
        <div className={styles.piMapSection}>
            <h1 className={styles.piMapTitle}>派e同城地图</h1>
            <img src={PiMapImage} alt="" className={styles.piMapMapImg} />
            {/* <div className={styles.piMapMapImgContainer}></div> */}
        </div>
    );
}