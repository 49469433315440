export const ChapterStatusEnum = {
    Alive: "0",
    Published: "1",
    Deleted: "2",
};

export const PartStatusEnum = {
    Alive: "0",
    Deleted: "1",
};

export const BookMode = {
    Read: 0,
    Edit: 1,
};

export const DefaultPartTitle = "Untitled";

export const Provinces = [{
    value: 'Beijing',
    label: '北京',
}, {
    value: 'Tianjin',
    label: '天津',
}, {
    value: 'Hebei',
    label: '河北',
}, {
    value: 'Shanxi',
    label: '山西',
}, {
    value: 'Inner Mongolia',
    label: '内蒙古',
}, {
    value: 'Liaoning',
    label: '辽宁',
}, {
    value: 'Jilin',
    label: '吉林',
}, {
    value: 'Heilongjiang',
    label: '黑龙江',
}, {
    value: 'Shanghai',
    label: '上海',
}, {
    value: 'Jiangsu',
    label: '江苏',
}, {
    value: 'Zhejiang',
    label: '浙江',
}, {
    value: 'Anhui',
    label: '安徽',
}, {
    value: 'Fujian',
    label: '福建',
}, {
    value: 'Jiangxi',
    label: '江西',
}, {
    value: 'Shandong',
    label: '山东',
}, {
    value: 'Henan',
    label: '河南',
}, {
    value: 'Hubei',
    label: '湖北',
}, {
    value: 'Hunan',
    label: '湖南',
}, {
    value: 'Guangdong',
    label: '广东',
}, {
    value: 'Guangxi',
    label: '广西',
}, {
    value: 'Hainan',
    label: '海南',
}, {
    value: 'Chongqing',
    label: '重庆',
}, {
    value: 'Sichuan',
    label: '四川',
}, {
    value: 'Guizhou',
    label: '贵州',
}, {
    value: 'Yunnan',
    label: '云南',
}, {
    value: 'Tibet',
    label: '西藏',
}, {
    value: 'Shaanxi',
    label: '陕西',
}, {
    value: 'Gansu',
    label: '甘肃',
}, {
    value: 'Qinghai',
    label: '青海',
}, {
    value: 'Ningxia',
    label: '宁夏',
}, {
    value: 'Xinjiang',
    label: '新疆',
}, {
    value: 'Taiwan',
    label: '台湾',
}, {
    value: 'Hong Kong',
    label: '香港',
}, {
    value: 'Macao',
    label: '澳门',
}];