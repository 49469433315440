import { API_BASE_URL } from './url';

const request = (options) => {
  const headers = new Headers({
    'Access-Control-Allow-Origin': '*'
  });

  const defaults = { headers: headers, credentials: "include" };
  options = Object.assign({}, defaults, options);

  return fetch(options.url, options)
    .then(response => response.json().then(json => {
      if (!response.ok) {
        return Promise.reject(json);
      }
      return json;
    })
    );
};

const concatGetParams = ({ relativePath, params = {} }) => {
  let result = "";
  let item;
  for (item in params) {
    if (params[item] && String(params[item])) {
      result += `&${item}=${params[item]}`;
    }
  }
  return `${API_BASE_URL}${relativePath}?${result.slice(1)}`;
}

// start region: APIs
export function GetCaptcha(params) {
  return request({
    url: concatGetParams({ relativePath: '/captcha', params }),
    method: 'GET',
  });
}

export function Login({ login_code, captcha }) {
  return request({
    url: `${API_BASE_URL}/user/login`,
    method: 'POST',
    body: new URLSearchParams(Object.entries({ login_code, captcha })),
  });
}

export function GetProducts({ name, province, merchant, merchantId, category, page = 1, size = 10 }) {
  return request({
    url: concatGetParams({ relativePath: '/products', params: { name, province, merchant, merchantId, category, page, size } }),
    method: 'GET',
  });
}

export function GetMerchants({ name, province, page = 1, size = 10 }) {
  return request({
    url: concatGetParams({ relativePath: '/merchants', params: { name, province, page, size } }),
    method: 'GET',
  });
}

export function GetMerchantInformation({ id }) {
  return request({
    url: concatGetParams({ relativePath: `/merchants/${id}` }),
    method: 'GET',
  });
}

export function GetProductCategories() {
  return request({
    url: concatGetParams({ relativePath: `/categories` }),
    method: 'GET',
  });
}
// end region