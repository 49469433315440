import { HomePageStyles as styles } from "../styles";
import _ from "lodash";
import React, { useEffect, useState } from "react";
import { message } from "antd";
import { NavigationFooter, NavigationHeaderIndex } from "../../component/navigation";
import { ProductsSection, IntroductionSection, PiMapSection, MerchantsSection, CategorySection } from "../../component/home";
import { useLocation } from "react-router";
import { HashRouter, Route, Routes, useSearchParams } from 'react-router-dom';

export function HomePage() {
    const [messageApi, contextHolder] = message.useMessage();
    const { state } = useLocation();
    const history = HashRouter.history;
    console.log('state', state);
    const { scrollTarget } = state || {};
    const [queryProductName, setQueryProductName] = useState(null);
    const navigateToProductsSection = () => {
        console.log(state, state?.scrollTarget);
        let productsSectionDiv = document.getElementById('products-section');
        const rect = productsSectionDiv.getBoundingClientRect();
        if (rect.top > 120) {
            window.scrollTo({ top: rect.top - 120, behavior: 'smooth' });
        } else {
            console.log('rect', rect.top, rect);
            productsSectionDiv.scrollIntoView();
        }
    }
    useEffect(() => {
        if (scrollTarget === 'ProductsSection') {
            navigateToProductsSection();
            window.history.replaceState({}, '');
            history.replaceState({});
        }
    }, [scrollTarget]);

    const onSearchProduct = ({ queryText }) => {
        setQueryProductName(queryText);
        navigateToProductsSection();
    };
    return (
        <div className={styles.homePage}>
            {contextHolder}
            <NavigationHeaderIndex
                navigateToProductsSection={navigateToProductsSection}
                showSearchBox
                onSearchProducts={onSearchProduct}
            />
            <IntroductionSection />
            <CategorySection />
            <MerchantsSection />
            <ProductsSection queryProductName={queryProductName} />
            <PiMapSection />
            <NavigationFooter
                navigateToProductsSection={navigateToProductsSection}

            />
        </div>
    )
}
