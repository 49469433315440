import React from "react";
import _ from "lodash";
import classname from "classnames";
import styles from "./navigation.module.css";
import { PiNetwork, PiBarterMall } from "../../assets";
import { SharedLinkGroup } from "./shared-link-group";
import { Link } from 'react-router-dom';

export function NavigationFooter({ navigateToProductsSection }) {
    return (
        <div className={classname("footer", styles.footer)}>
            <div className={classname("navigation-footer-tabs", styles.navigationTab, styles.footerTab)}>
                <Link to="/whitepaper" className={classname("navigation-tab-item", styles.navigationTabItem)}>白皮书</Link>
                <Link to="/home#products-section" className={classname("navigation-tab-item", styles.navigationTabItem)}>
                    商品展示
                </Link>
                {/* <a href="#" className={classname("navigation-tab-item", styles.navigationTabItem)}>PPT讲义</a>
                <a href="#" className={classname("navigation-tab-item", styles.navigationTabItem)}>招贤纳士</a>
                <Link to="/about" className={classname("navigation-tab-item", styles.navigationTabItem)}>关于我们</Link>
                <a href="#" className={classname("navigation-tab-item", styles.navigationTabItem)}>联系我们</a> */}
                <Link to="/slide" className={classname("navigation-tab-item", styles.navigationTabItem)}>PPT讲义</Link>
                <a href="mailto: official.paiyitongcheng@gmail.com" className={classname("navigation-tab-item", styles.navigationTabItem)}>招贤纳士</a>
                <Link to="/about" className={classname("navigation-tab-item", styles.navigationTabItem)}>关于我们</Link>
                <a href="mailto: paiyitongcheng@gmail.com" className={classname("navigation-tab-item", styles.navigationTabItem)}>联系我们</a>
                <Link to="/terms" className={classname("navigation-tab-item", styles.navigationTabItem)}>使用条款</Link>
                {/* <a href="/terms" className={classname("navigation-tab-item", styles.navigationTabItem)}>使用条款</a> */}
            </div>
            <div className={styles.rightGoup}>
                <SharedLinkGroup />
                <img src={PiNetwork} alt="" height="40" />
                <img src={PiBarterMall} alt="" height="35" />
            </div>
        </div>
    );
}