import React, { useState, useEffect } from 'react';
import { GetProductCategories } from '../../constants/apiUtils';
import styles from './section.module.css';

function CategoryItem({ name, image: imageUrl, width, height }) {
    return (
        <div className={styles.categoryItem} style={{ width, height }}>
            <img src={imageUrl} className={styles.categoryImage} alt='' />
            <div className={styles.categoryName}>{name}</div>
        </div>
    );
}

export function CategorySection() {
    const [categories, setCategories] = useState([]);
    useEffect(() => {
        GetProductCategories().then(response => {
            setCategories(response.data);
        }).catch(error => {
            console.log('GetProductCategories error', error);
        });
    }, []);
    return (
        <div className={styles.categorySection}>
            <div className={styles.categoryLeftContainer}>
                <h1 className={styles.categorySectionTitle}>商品分类</h1>
            </div>
            <div className={styles.categoryContent}>
                <div className={styles.topCategory}>
                    <CategoryItem key={`largeCategory-1`} {...categories[0]} width={445} height={450} />
                    <div className={styles.middleCategory}>
                        {categories.length > 1 && categories.slice(1, 3).map((midCategory, index) => (
                            <CategoryItem key={`midCategory-${index}`} {...midCategory} width={395} height={210} />
                        ))}
                    </div>
                </div>
                <div className={styles.lowCategory}>
                    {categories.length > 3 && categories.slice(3, 7).map((smallCategory, index) => (
                        <CategoryItem key={`smallCategory-${index}`} {...smallCategory} width={200} height={200} />
                    ))}
                </div>
            </div>
        </div>
    )
}