import React from 'react';
import classname from 'classnames';
import styles from "./navigation.module.css";
import { SharedYellowLogos, SharedGrayLogos, SharedDefaultLogos } from "../../assets";

const getSharedLinkLogoGroup = ({ logoType }) => {
    switch (logoType) {
        case 'yellow':
            return SharedYellowLogos;
        case 'gray':
            return SharedGrayLogos;
        default:
            return SharedDefaultLogos;
    }
};

export function SharedLinkGroup({ className, itemClassName, logoType = 'default' } = {}) {
    let sharedLinkLogoGroup = getSharedLinkLogoGroup({ logoType });
    return (
        <div className={classname("shared-link-group", styles.sharedLinkGroup, className)}>
            <a href="https://www.youtube.com/@official-paiyitongcheng"><img src={sharedLinkLogoGroup.youtube} className={classname(styles.sharedLinkItem, itemClassName)} /></a>
            <a href="https://facebook.com"><img src={sharedLinkLogoGroup.facebook} className={classname(styles.sharedLinkItem, itemClassName)} /></a>
            <a href="https://twitter.com/official_paiyi"><img src={sharedLinkLogoGroup.twitter} className={classname(styles.sharedLinkItem, itemClassName)} /></a>
            <a href="https://t.me/PaiYiTongCheng"><img src={sharedLinkLogoGroup.telegram} className={classname(styles.sharedLinkItem, itemClassName)} /></a>
        </div>
    );
}