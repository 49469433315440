import { HomePageStyles as styles } from "../styles";
import React, { useEffect } from "react";
import { message } from "antd";
import { NavigationFooter, NavigationHeaderIndex } from "../../component/navigation";
import { useLocation } from "react-router";
import { HashRouter } from 'react-router-dom';
import { AboutImage } from '../../assets'

export function About() {
    const [messageApi, contextHolder] = message.useMessage();
    const { state } = useLocation();
    const history = HashRouter.history;
    const { scrollTarget } = state || {};

    useEffect(() => {
        if (scrollTarget === 'ProductsSection') {
            window.history.replaceState({}, '');
            history.replaceState({});
        }
    }, [scrollTarget]);

    return (
        <div className={styles.homePage}>
            {contextHolder}
            <NavigationHeaderIndex />
            <div className={styles.imageContainer}>
                <img
                    src={AboutImage}
                    className={styles.responsiveImage}
                />
            </div>
            <NavigationFooter />
        </div>
    )
}