import React, { useEffect, useState } from "react";
import _ from "lodash";
import classname from "classnames";
import { Input } from 'antd';
import styles from "./navigation.module.css";
import './navigation.css';
import { Link } from 'react-router-dom';
import { PiNetwork, PiBarterMall, MenuIcon, SearchIcon } from "../../assets";

const { Search } = Input;

export function NavigationHeader({ navigateToProductsSection, showSearchBox = false, onSearchProducts }) {
    const [queryText, setQueryText] = useState(null);
    return (
        <div className={classname("header", styles.header)}>
            <div><img src={MenuIcon} className={styles.headerIcon} /></div>
            <div className={classname("logo", styles.logo)}>
                <Link to="/home"> <img className={styles.logoImage} src={PiNetwork} alt="" height="45" /> </Link>
                <Link to="/home"> <img className={styles.logoImage} src={PiBarterMall} alt="" height="40" /> </Link>
            </div>
            <div className={classname("navigation-tabs", styles.navigationTab)}>
            <Link to="/whitepaper" className={styles.navigationTabItem}>白皮书</Link>
            <Link to="/home#products-section" className={classname("navigation-tab-item", styles.navigationTabItem)}>商品展示</Link>
            <Link to="/slide" className={styles.navigationTabItem}>PPT讲义</Link>
            <a href="mailto:official.paiyitongcheng@gmail.com" className={styles.navigationTabItem}>招贤纳士</a>
            <Link to="/about" className={classname("navigation-tab-item", styles.navigationTabItem)}>关于我们</Link>
            </div>
            {showSearchBox && <Input
                className={styles.searchBox}
                suffix={<img src={SearchIcon} className={styles.headerIcon} onClick={() => { onSearchProducts({ queryText }); }} />}
                onChange={(e) => { setQueryText(e.target.value); }}
            // onMouseEnter={() => { onSearchProducts({ queryText }); }}
            />}
        </div>
    );
}