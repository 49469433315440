import React, { useState, useEffect } from 'react';
import { useNavigate } from "react-router";
import classname from 'classnames';
import { Input, Select, Button, message, Pagination, Row, Col } from 'antd';
import _ from 'lodash';
import styles from './section.module.css';
import './product.css';
import { MerchantItem } from '../commodity';
import { Provinces } from '../../constants/constants';
import { GetMerchants } from '../../constants/apiUtils';
import { MoreCommoditys } from '../../constants/mock-data';

export function MerchantsSection() {
    const navigate = useNavigate();
    const [messageApi, contextHolder] = message.useMessage();
    const [queryName, setQueryName] = useState(null);
    const [selectedProvince, setSelectedProvince] = useState(null);
    const [page, setPage] = useState(1);
    const [size, setSize] = useState(12);
    const [merchants, setMerchants] = useState([]);
    const [totalCount, setTotalCount] = useState(0);

    useEffect(() => {
        GetMerchants({ page, size }).then(response => {
            if (_.isArray(response.data)) {
                setMerchants(response.data);
                setTotalCount(response.total);
            } else {
                throw new Error("response is not array");
            }
        }).catch(error => {
            console.log('GetMerchants error', error);
            messageApi.error(error.message);
        });
    }, []);

    const onSearch = async (overrideParams) => {
        console.log('onsearch', queryName, selectedProvince, size, page, overrideParams);
        await GetMerchants({
            name: queryName,
            province: selectedProvince,
            page,
            size,
            ...overrideParams,
        }).then(response => {
            if (_.isArray(response.data)) {
                setMerchants(response.data);
                setTotalCount(response.total);
            } else {
                throw new Error("response is not array");
            }
        }).catch(error => {
            console.log('onsearch, GetMerchants error', error);
        });
    }

    const onChangePage = (newPage) => {
        setPage(newPage);
        onSearch({ page: newPage });
    }

    const navigateToDetails = ({ id }) => {
        navigate(`/merchant/${id}`);
    }

    return (
        <div className={styles.merchantsSection}>
            {contextHolder}
            <div className={styles.toolbar}>
                <div className={styles.merchantTitle}>商家展示</div>
                <div className={styles.queryGroup}>
                    <Input
                        className={classname(styles.queryItem)}
                        placeholder="输入商家名称"
                        size='large'
                        shape='round'
                        onChange={(e) => { setQueryName(e.target.value); }}
                    />
                    <Select
                        allowClear
                        className={classname(styles.queryItem)}
                        options={Provinces}
                        size='large'
                        placeholder="选择省份"
                        onChange={(value, args2) => { setSelectedProvince(value); console.log(value, args2); }}
                    />
                    <Button className={styles.queryCommodityButton} size='large' shape='round' onClick={onSearch}>搜索</Button>
                </div>
            </div>
            <Row className={styles.productsGroup}>
                {merchants.length > 0 && merchants.map(mcht => (
                    <Col span={4} key={`merchant-list-${mcht.id}-${mcht.name}-col`}>
                        <MerchantItem
                            key={`merchant-list-${mcht.id}-${mcht.name}`}
                            {...mcht}
                            onClick={navigateToDetails}
                        />
                    </Col>
                ))}
            </Row>
            <Pagination
                showQuickJumper
                className={styles.merchantPagination}
                defaultCurrent={page}
                total={totalCount}
                pageSize={10}
                onChange={onChangePage}
            />
        </div>
    )
}