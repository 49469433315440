import { HomePageStyles as styles } from "../styles";
import React from "react";
import { NavigationFooter, NavigationHeaderIndex } from "../../component/navigation";
import { SlidePDF as SlidePDFFile } from '../../assets'

export function SlidePDFViewer() {
    return (
        <div className={styles.homePage}>
            <NavigationHeaderIndex />
            <div className={styles.pdfContainer}>
                <iframe
                    src={SlidePDFFile}
                    className={styles.responsivePDF}
                    height="3000px"
                    width="100%"
                ></iframe>
            </div>
            <NavigationFooter />
        </div>
    )
}