import React, { useEffect, useState } from "react";
import _ from "lodash";
import classname from "classnames";
import styles from "./navigation.module.css";
import { PiNetwork, PiBarterMall } from "../../assets";

export function NavigationHeader() {
    return (
        <div className={classname("header", styles.header, styles.loginHeader)}>
            <div className={classname("logo", styles.logo)}>
                <img className={styles.logoImage} src={PiNetwork} alt="" height="45" />
                <img className={styles.logoImage} src={PiBarterMall} alt="" height="40" />
            </div>
            <div className={classname("navigation-tabs", styles.navigationTab)}>
                <div><a href="mailto: paiyitongcheng@gmail.com" className={classname("navigation-tab-item", styles.navigationTabItem)}>联系我们</a></div>
                <div><a href="mailto: paiyitongcheng@gmail.com" className={classname("navigation-tab-item", styles.navigationTabItem)}>问题反馈</a></div>
                <div><a href="mailto: official.paiyitongcheng@gmail.com" className={classname("navigation-tab-item", styles.navigationTabItem)}>招贤纳士</a></div>
                <div><a href="mailto: paiyitongcheng@gmail.com" className={classname("navigation-tab-item", styles.navigationTabItem)}>寻求合作</a></div>
            </div>
        </div>
    );
}