import { LoginPageStyles as styles } from "../styles";
import React from "react";
import _ from "lodash";
import { Carousel, ConfigProvider } from "antd";
import { NavigationHeaderLogin, SharedLinkGroup } from "../../component/navigation";
import { NewsBgImage, SignInBackground, SignInTitleImage } from "../../assets";
import { CarouselData } from "../../constants/mock-data";
import { LoginAction } from "../../component/login";

export function LoginPage() {
    return (
        <div className={styles.loginPage}>
            <img src={SignInBackground} className={styles.signInBgImg} alt="" />
            <NavigationHeaderLogin />
            <div className={styles.container}>
                <div className={styles.leftContainer}>
                    <img className={styles.newsBgImg} src={NewsBgImage} alt="" />
                    <div className={styles.carouselContainer}>
                        <ConfigProvider
                            theme={{ token: { dotWidth: 3 } }}>
                            <Carousel
                                autoplay
                                className={styles.carousel}
                                dotPosition="bottom"
                            >
                                {CarouselData.map((cimage, index) => {
                                    return (
                                        <a className={styles.carouselItem} href={cimage.toUrl}><img className={styles.carouselItem} src={cimage.imageSrc} alt="" /></a>
                                    );
                                })}
                            </Carousel>
                        </ConfigProvider>
                    </div>
                </div>
                <div className={styles.actionContainer}>
                    <div className={styles.title}>
                        <img id="title-bg" className={styles.titleBgImg} src={SignInTitleImage} alt="" />
                        <h1 className={styles.titleText}>派e同城信息门户系统</h1>
                    </div>
                    <LoginAction />
                    <SharedLinkGroup className={styles.sharedLinkGroup} logoType="gray" />
                </div>
            </div>
        </div>
    )
}
