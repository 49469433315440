import React from 'react';
import classname from 'classnames';
import { Card, Avatar, Image } from 'antd';
import styles from './merchant.module.css';

export function MerchantDetails({
    className,
    logo,
    name,
    introduction,
    license_image,
    contact,
    address,
    barter_food_link,
    onClick,
}) {
    return (
        <Card className={classname(styles.merchantDetailsCard, className)} bordered onClick={onClick}>
            <div className={styles.merchantContent}>
                <Avatar
                    className={styles.avatar}
                    src={logo}
                />
                <div className={styles.merchantName}>{name}</div>
                <Image src={license_image} />
                <div className={styles.merchantDetails}>
                    <div className={styles.itemLabel}>商家介绍: </div>
                    <div className={styles.itemContent}>{introduction}</div>
                    <div className={styles.itemLine}>
                        <span className={styles.itemLabel}>联系方式: </span>
                        <span className={styles.itemContent}>{contact}</span>
                    </div>
                    <div className={styles.itemLine}>
                        <span className={styles.itemLabel}>线下地址: </span>
                        <span className={styles.itemContent}>{address}</span>
                    </div>
                    <div className={styles.itemLine}>
                        <span className={styles.itemLabel}>易货视频链接: </span>
                        <span className={styles.itemContent}><a target='_blank' href={barter_food_link} rel="noreferrer">{barter_food_link}</a></span>
                    </div>
                </div>
            </div>
        </Card>
    );

}