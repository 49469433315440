import React from 'react';
import classname from 'classnames';
import _ from 'lodash';
import { Card, Avatar } from 'antd';
import styles from './merchant.module.css';
import { Provinces } from '../../constants/constants';

export function MerchantItem({
    className,
    height = 220,
    id,
    logo,
    name,
    province,
    onClick,
}) {
    const provinceItem = _.find(Provinces, (item) => item.value === province);
    return (
        <div className={styles.merchantItem}>
            <Card
                hoverable
                bodyStyle={{ padding: 0, margin: 0, height: '100%' }}
                className={classname(styles.card, className)}
                style={{ width: '90%', height }}
                bordered
                onClick={() => { onClick({ id }); }}
            >
                <div className={styles.merchantContent}>
                    <Avatar className={styles.avatar} src={logo} />
                    <div className={styles.merchantItemName}>{name}</div>
                    <div className={styles.itemLine}>
                        <span className={styles.itemLabel}>所属省份:</span>
                        <span className={styles.itemContent}>{provinceItem?.label}</span>
                    </div>
                </div>
            </Card>
        </div>
    );

}