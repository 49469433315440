import React, { useState } from 'react';
import classname from 'classnames';
import { Card, Modal } from 'antd';
import styles from './product.module.css';

export function ProductItem({
    className,
    height = 320,
    image: imageUrl,
    name,
    price,
}) {
    const [isModalVisible, setIsModalVisible] = useState(false);

    const handleImageClick = () => {
        setIsModalVisible(true);
    };

    const handleClose = () => {
        setIsModalVisible(false);
    };

    return (
        <div className={styles.productItem}>
            <Card
                hoverable
                bodyStyle={{ padding: 0, margin: 0, height: '100%' }}
                className={classname(styles.card, className)}
                style={{ width: '90%', height }}
            >
                <div className={styles.productBody}>
                    <div className={styles.productImgWrap}>
                        <img
                            className={styles.productImgWrapImage}
                            src={imageUrl}
                            alt=""
                            onClick={handleImageClick}
                        />
                    </div>
                    <div className={styles.description}>
                        <div className={styles.productName}>{name}</div>
                        <div className={styles.productPrice}>原价￥{price}</div>
                    </div>
                </div>
            </Card>
            <Modal
                visible={isModalVisible}
                footer={null}
                onCancel={handleClose}
                bodyStyle={{ padding: 0 }}
                width="80%"
                style={{ top: 0 }}
                centered
            >
                <img
                    src={imageUrl}
                    alt=""
                    style={{ width: '100%', height: '100vh', objectFit: 'contain' }}
                />
            </Modal>
        </div>
    );
}