import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router";
import { Pagination, Row, Col, message } from "antd";
import _ from 'lodash';
import { MerchantPageStyles as styles } from "../styles";
import { NavigationFooter, NavigationHeaderIndex } from "../../component/navigation";
import { MerchantDetails, MerchantImagesGroup, ProductItem } from "../../component/commodity";
import { Commoditys, MerchantInfo } from "../../constants/mock-data";
import { GetMerchantInformation, GetProducts } from "../../constants/apiUtils";

export function MerchantPage() {
    const params = useParams();
    const navigate = useNavigate();
    const { merchantId } = params;
    const [messageApi, contextHolder] = message.useMessage();
    const [merchantInfo, setMerchantInfo] = useState({});
    const [products, setProducts] = useState([]);
    const [page, setPage] = useState(1);
    const [size, setSize] = useState(6);
    const [totalCount, setTotalCount] = useState(0);
    useEffect(() => {
        GetMerchantInformation({ id: merchantId }).then(response => {
            if (response && `${response.id}` === merchantId) {
                setMerchantInfo(response);
            } else {
                throw new Error("merchant response is not valid");
            }
        }).catch(error => {
            console.log('GetMerchantInformation error', error);
            messageApi.error('GetMerchantInformation error', error.message);
        });
        if (merchantId > 0) {
            GetProducts({ merchantId, page, size }).then(response => {
                if (_.isArray(response.data)) {
                    setProducts(response.data);
                    setTotalCount(response.total);
                } else {
                    throw new Error("response is not array");
                }
            }).catch(error => {
                console.log('GetProducts error', error);
                messageApi.error('load page GetProducts error', error.message);
            });
        }
    }, [merchantId]);
    const navigateToProductsSection = () => {
        navigate('/home', { state: { scrollTarget: 'ProductsSection' } });
    }
    const onChangePage = async (newPage) => {
        setPage(newPage);
        await GetProducts({ merchantId, page: newPage, size }).then(response => {
            if (_.isArray(response.data)) {
                setProducts(response.data);
                setTotalCount(response.total);
            } else {
                throw new Error("response is not array");
            }
        }).catch(error => {
            console.log('GetProducts error', error);
            messageApi.error('onChangePage GetProducts error', error.message);
        });
    }

    return (
        <div className={styles.merchantPage}>
            {contextHolder}
            <NavigationHeaderIndex
                navigateToProductsSection={navigateToProductsSection}
                showSearchBox={false}
            />
            {merchantInfo.id &&
                <div className={styles.merchantPageContainer}>
                    <div className={styles.merchantInfoContainer}>
                        <MerchantDetails {...merchantInfo} />
                    </div>
                    <div className={styles.commodityInfoContainer}>
                        <h1 className={styles.productListTitle}>商品列表</h1>
                        <Row className={styles.productsGroup}>
                            {products.length > 0 && products.map(cmdt => (
                                <Col span={8} key={`commodity-list-${cmdt.id}-${cmdt.name}-col`}>
                                    <ProductItem
                                        // key={`commodity-list-${cmdt.id}-${cmdt.name}`}
                                        {...cmdt}
                                    />
                                </Col>
                            ))}
                        </Row>
                        <Pagination
                            showQuickJumper
                            className={styles.productPagination}
                            defaultCurrent={page}
                            total={totalCount}
                            pageSize={6}
                            onChange={onChangePage}
                        />
                    </div>
                </div>}
            {merchantInfo.images &&
                <MerchantImagesGroup
                    imageUrls={merchantInfo.images}
                    numberPerRow={4}
                />}
            <NavigationFooter navigateToProductsSection={navigateToProductsSection} />
        </div>
    );
}
