import React from 'react';
import { Button, Carousel, ConfigProvider } from 'antd';
import styles from './section.module.css';
import { CarouselData } from '../../constants/mock-data';

export function IntroductionSection() {
    return (
        <div className={styles.introductionSection}>
            <div className={styles.carouselContainer}>
                <div className={styles.carouselContent}>
                    <ConfigProvider
                        theme={{ token: { dotWidth: 3 } }}>
                        <Carousel
                            key="introduction-carousel"
                            autoplay
                            dotPosition="bottom"
                        >
                            {CarouselData.map((cimage, index) => {
                                return (<img alt='' key={`introduction-${index}`} className={styles.carouselItem} src={cimage.imageSrc} />);
                            })}
                        </Carousel>
                    </ConfigProvider>
                </div>
            </div>
            <div className={styles.textContainer}>
                <h1 className={styles.introText}>通过数字经济学模型和先进的技术为广大老百姓搭建分布式数字经济场景，让每个人的消费购买力这一本身推动实业发展的经济资源得以有效地转化为属于自己的经济实惠，从而将推动实体经济发展的效率上升一个更高的台阶！</h1>
                <Button className={styles.learnMoreButton} size="large" ghost shape='round'>了解更多</Button>
            </div>
        </div>
    )
}