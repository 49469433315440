import React, { useState } from "react";
import _ from "lodash";
import classname from "classnames";
import { Row, Col, Modal } from 'antd';
import { LeftLastDisabledIcon, LeftLastIcon, RightNextDisabledIcon, RightNextIcon } from '../../assets';
import styles from "./merchant-images-group.module.css";

export function MerchantImagesGroup({
    imageUrls,
    numberPerRow,
}) {
    const [hoveringIndex, setHoveringIndex] = useState(-1);
    const [isModalVisible, setIsModalVisible] = useState(false); 
    const [selectedImageUrl, setSelectedImageUrl] = useState("");
    const imagesSlice = _.chunk(imageUrls.map((iurl, index) => ({
        index,
        imgUrl: iurl,
    })), numberPerRow);
    const sliceLength = imagesSlice.length;
    const [curSlice, setCurSlice] = useState({
        index: 0,
        imageSlice: imagesSlice[0],
        needCoveringCount: numberPerRow - imagesSlice[0].length,
    });

    const handleImageClick = (imgUrl) => {
        setSelectedImageUrl(imgUrl); 
        setIsModalVisible(true); 
    };

    const handleClose = () => {
        setIsModalVisible(false); 
    };

    const getNextSlice = () => {
        setCurSlice(pre => ({
            index: pre.index + 1,
            imageSlice: imagesSlice[pre.index + 1],
            needCoveringCount: numberPerRow - imagesSlice[pre.index + 1].length,
        }));
    };

    const getLastSlice = () => {
        setCurSlice(pre => ({
            index: pre.index - 1,
            imageSlice: imagesSlice[pre.index - 1],
            needCoveringCount: numberPerRow - imagesSlice[pre.index - 1].length,
        }));
    };

    const onHoverImage = ({ index }) => {
        setHoveringIndex(index);
    }

    const onMouthLeaveImage = () => {
        setHoveringIndex(-1);
    }

    return (
        <div className={styles.merchantImagesGroup}>
            <h1 className={styles.title}>商家图片介绍</h1>
            <Row className={styles.imagesGroup}>
                <Col span={2}>
                    {curSlice.index === 0 ?
                        <img className={styles.stepIcon} src={LeftLastDisabledIcon} /> :
                        <img className={styles.stepIcon} src={LeftLastIcon} onClick={getLastSlice} />}
                </Col>
                {imageUrls.length > 0 && curSlice.imageSlice.map(({ imgUrl }, index) => (
                    <>
                        <Col span={4}>
                            <img
                                key={`${imgUrl}-${index}`}
                                className={hoveringIndex === index ? styles.perHoveringImage : styles.perImage}
                                src={imgUrl}
                                onMouseEnter={() => onHoverImage({ index })}
                                onMouseLeave={onMouthLeaveImage}
                                onClick={() => handleImageClick(imgUrl)}
                            />
                        </Col>
                        <Col span={1}></Col>
                    </>
                ))}
                {curSlice.needCoveringCount > 0 && <Col span={curSlice.needCoveringCount * 5}></Col>}
                <Col span={2}>
                    {sliceLength < 2 || curSlice.index === sliceLength - 1 ?
                        <img className={classname(styles.stepIcon, styles.rightIcon)} src={RightNextDisabledIcon} /> :
                        <img className={classname(styles.stepIcon, styles.rightIcon)} src={RightNextIcon} onClick={getNextSlice} />}
                </Col>
            </Row>
            <Modal
                visible={isModalVisible}
                footer={null}
                onCancel={handleClose}
                bodyStyle={{ padding: 0 }}
                width="80%"
                style={{ top: 0 }}
                centered
            >
                <img
                    src={selectedImageUrl}
                    alt=""
                    style={{ width: '100%', height: '100vh', objectFit: 'contain' }}
                />
            </Modal>
        </div>

    );
}
