import React from 'react';
import ReactDOM from 'react-dom';
import { HashRouter, Route, Routes } from 'react-router-dom';
import './index.css';
import './index.module.css';
import "lib-flexible";
import { LoginPage, MerchantPage, HomePage, TermsOfUse, About, WhitepaperPDFViewer, SlidePDFViewer} from './pages/control';

ReactDOM.render(
    <HashRouter>
        <Routes>
            <Route path="/home" element={<HomePage />}></Route>
            <Route path="/" element={<LoginPage />}></Route>
            <Route path="/merchant/:merchantId" element={<MerchantPage />} />
            <Route path="/terms" element={<TermsOfUse />}></Route>
            <Route path="/about" element={<About />}></Route>
            <Route path="/whitepaper" element={<WhitepaperPDFViewer />}></Route>
            <Route path="/slide" element={<SlidePDFViewer />}></Route>
        </Routes>
    </HashRouter>,
    document.getElementById('root')
);
