import { HomePageStyles as styles } from "../styles";
import _ from "lodash";
import React, { useEffect, useState } from "react";
import { message } from "antd";
import { NavigationFooter, NavigationHeaderIndex } from "../../component/navigation";
import { useLocation } from "react-router";
import { HashRouter} from 'react-router-dom';

export function TermsOfUse () {
    const [messageApi, contextHolder] = message.useMessage();
    const { state } = useLocation();
    const history = HashRouter.history;
    const { scrollTarget } = state || {};
    const [queryProductName, setQueryProductName] = useState(null);
    const navigateToProductsSection = () => {
        console.log(state, state?.scrollTarget);
        let productsSectionDiv = document.getElementById('products-section');
        const rect = productsSectionDiv.getBoundingClientRect();
        if (rect.top > 120) {
            window.scrollTo({ top: rect.top - 120, behavior: 'smooth' });
        } else {
            console.log('rect', rect.top, rect);
            productsSectionDiv.scrollIntoView();
        }
    }
    useEffect(() => {
        if (scrollTarget === 'ProductsSection') {
            navigateToProductsSection();
            window.history.replaceState({}, '');
            history.replaceState({});
        }
    }, [scrollTarget]);

    const onSearchProduct = ({ queryText }) => {
        setQueryProductName(queryText);
        navigateToProductsSection();
    };
    return (
        <div className={styles.homePage}>
            {contextHolder}
            <NavigationHeaderIndex
                navigateToProductsSection={navigateToProductsSection}
                showSearchBox
                onSearchProducts={onSearchProduct}
            />
            <main className={styles.termsContainer}>
                <div className={styles.termsText}>
                    <h1 className={styles.termsTextH1}>使用条款</h1>
                    <div>
                    <p className={styles.termsTextP}>派易同城. 为派赋能生态事业的市场发展严格遵循当地的法律法规的原则进行，任何用户参与派易同城.为派赋能生态事业的一切活动也应当遵守相关的法律法规。因此，如果您在参与派易同城.为派赋能事业使用任何有关服务，您和我们双方同意这些条款将构成您和我们之间具有约束力的、具有法律效力的合同。</p>
                    <li className={styles.termsTextP}>派易同城.为派赋能生态事业在发展过程中严禁使用夸大、虚假宣传的方式诱导用户参与，即用户参与派易同城.为派赋能事业之前应被给予客观真实的信息；</li>
                    <li className={styles.termsTextP}>派生态同城数字权益PDE作为一种数字化的消费积分形式服务于实体经济，因此我们严禁任何人在使用派生态同城数字权益PDE的过程中只转让不易货，或者以极小部分的派生态同城数字权益PDE形式化地易货。对于违规者，我们将有权封禁用户地址；</li>
                    <li className={styles.termsTextP}>每一位参与用户必须经过KYC实名认证，而且必须保证每一位用户只拥有一个地址。任何人利用他人的身份伪造多余账户地址的行为，一经查实，相关账户地址会被封禁。对于情节严重的行为，我们将积极配合执法部门依法追究相关人员的法律责任；</li>
                    <li className={styles.termsTextP}>每一枚派生态同城数字权益PDE在消费易货中等同于1美元的消费购买力，因此严禁任何用户以高于或低于1美元的消费购买力兑换商品或服务。对于有该项违规行为的用户，一经发现，证据确凿，我们有权对其账户地址进行封禁；</li>
                    <li className={styles.termsTextP}>对于每一位新用户的参与必须有一位自己的直属推荐人。一般情况下，直属推荐人是向新用户进行讲解并给予派易同城信息平台门户登陆码的老用户。新用户务必通过其直属推荐人参与派易同城.为派赋能生态事业。包括后续新用户增加更多派生态同城数字权益PDE的渠道必须通过其直属推荐人进行。如果直属推荐人无法满足其增加PDE的需求，该用户才有权向更上一级的直属推荐人增加自身所需的PDE。对于有任何恶意争夺新用户的行为，平台有权封禁其和新用户的地址（对于直属推荐人有不作为行为等特殊情况除外）；</li>
                    <li className={styles.termsTextP}>每一位用户在发展新用户时，请务必指导新用户正确进行KYC实名认证操作。如果有新用户向平台客服反映其直属推荐人在向其转让派生态同城数字权益PDE之后并未及时指导其进行KYC操作，我们有权封禁该推广者的地址；</li>
                    <li className={styles.termsTextP}>严禁任何消费者和商家进行虚假交易。如有发现，证据确凿，平台方有权封禁所有相关地址。如有情节严重的情况，平台方将积极配合执法机关依法追究相关人员的法律责任；</li>
                    <li className={styles.termsTextP}>任何商家不能将自己手中的派生态同城数字权益PDE转让给不是自己开拓的消费者用户，如有此违规行为，平台方有权封禁相关地址并下架该违规商家；</li>
                    <li className={styles.termsTextP}>严禁任何用户将派生态同城数字权益PDE用于帮助违规商品或服务、无安全资质的商品或服务进行销货。如果有此情形，平台方有权封禁所有相关地址。如有情节严重的情况，平台方将积极配合执法机关依法追究相关人员的法律责任。</li>
                    </div>
                </div>
            </main>
            <NavigationFooter
                navigateToProductsSection={navigateToProductsSection}
            />
        </div>
    )
}
