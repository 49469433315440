import { Carousel1, Carousel2, Carousel3, Carousel4, Carousel5, Carousel6, Carousel7, Carousel8, FoodCategory } from "../assets";

export const CarouselData = [
    { imageSrc: Carousel1, toUrl: 'https://brainstorm.pinet.com/' },
    { imageSrc: Carousel2, toUrl: 'https://fireside.pinet.com/' },
    { imageSrc: Carousel3, toUrl: 'https://x.com/picoreteam/status/1793764907814638001?s=12&t=368JHRbf1kiVl8mmiqhrtA' },
    { imageSrc: Carousel4, toUrl: 'https://twitter.com/picoreteam/status/1770543766178586731?s=46&t=368JHRbf1kiVl8mmiqhrtA' },
    { imageSrc: Carousel5, toUrl: 'https://twitter.com/picoreteam/status/1780378364320379353?s=46&t=368JHRbf1kiVl8mmiqhrtA' },
    { imageSrc: Carousel6, toUrl: 'https://paiyi.io/' },
    { imageSrc: Carousel7, toUrl: 'https://paiyi.io/' },
    { imageSrc: Carousel8, toUrl: 'https://paiyi.io/' },
];

export const Categories = [
    { id: 1, name: '111', imageUrl: FoodCategory, value: 1, label: '111' },
    { id: 2, name: '222', imageUrl: FoodCategory, value: 2, label: '222' },
    { id: 3, name: '333', imageUrl: FoodCategory, value: 3, label: '333' },
    { id: 4, name: '444', imageUrl: FoodCategory, value: 4, label: '444' },
    { id: 5, name: '555', imageUrl: FoodCategory, value: 5, label: '555' },
    { id: 6, name: '666', imageUrl: FoodCategory, value: 6, label: '666' },
    { id: 7, name: '777', imageUrl: FoodCategory, value: 7, label: '777' },
];

export const CityPartyNews = [
    "第一条信息第一条信息第一条信息第一条信息第一条信息",
    "第一条信息第一条信息第一条信"
];

export const Commoditys = [{
    id: 1,
    imageUrl: 'https://k.sinaimg.cn/n/sinakd20240208s/189/w500h489/20240208/0e2c-b8288392180f5f25071d7d4cae908ce8.jpg/w700d1q75cms.jpg?by=cms_fixed_width',
    name: 'mock 1',
    price: 23,
    likedCount: 34,
}, {
    id: 2,
    imageUrl: 'https://static.cnbetacdn.com/thumb/article/2021/0908/a8e8bba2ea672cf.jpg',
    name: 'mock 1',
    price: 23,
    likedCount: 34,
}, {
    id: 3,
    imageUrl: 'https://img2.baidu.com/it/u=588175144,3614862862&fm=253&fmt=auto&app=138&f=JPEG?w=500&h=278',
    name: 'mock 1',
    price: 23,
    likedCount: 34,
}, {
    id: 4,
    imageUrl: 'https://www.9to5carwallpapers.com/wp-content/uploads/2015/04/Porsche-Panamera-Dusky-Hd-Wallpaper.jpg',
    name: 'mock 1',
    price: 23,
    likedCount: 34,
}];

export const MoreCommoditys = [{
    id: 1,
    imageUrl: 'https://img2.baidu.com/it/u=588175144,3614862862&fm=253&fmt=auto&app=138&f=JPEG?w=500&h=278',
    name: 'mock 1',
    price: 23,
    likedCount: 34,
}, {
    id: 2,
    imageUrl: 'https://img2.baidu.com/it/u=588175144,3614862862&fm=253&fmt=auto&app=138&f=JPEG?w=500&h=278',
    name: 'mock 2',
    price: 23,
    likedCount: 34,
}, {
    id: 3,
    imageUrl: 'https://img2.baidu.com/it/u=588175144,3614862862&fm=253&fmt=auto&app=138&f=JPEG?w=500&h=278',
    name: 'mock 3',
    price: 23,
    likedCount: 34,
}, {
    id: 4,
    imageUrl: 'https://img2.baidu.com/it/u=588175144,3614862862&fm=253&fmt=auto&app=138&f=JPEG?w=500&h=278',
    name: 'mock 4',
    price: 23,
    likedCount: 34,
}, {
    id: 5,
    imageUrl: 'https://img2.baidu.com/it/u=588175144,3614862862&fm=253&fmt=auto&app=138&f=JPEG?w=500&h=278',
    name: 'mock 5',
    price: 23,
    likedCount: 34,
}, {
    id: 6,
    imageUrl: 'https://img2.baidu.com/it/u=588175144,3614862862&fm=253&fmt=auto&app=138&f=JPEG?w=500&h=278',
    name: 'mock 6',
    price: 23,
    likedCount: 34,
}, {
    id: 7,
    imageUrl: 'https://img2.baidu.com/it/u=588175144,3614862862&fm=253&fmt=auto&app=138&f=JPEG?w=500&h=278',
    name: 'mock 7',
    price: 23,
    likedCount: 34,
}, {
    id: 8,
    imageUrl: 'https://img2.baidu.com/it/u=588175144,3614862862&fm=253&fmt=auto&app=138&f=JPEG?w=500&h=278',
    name: 'mock 8',
    price: 23,
    likedCount: 34,
}, {
    id: 9,
    imageUrl: 'https://img2.baidu.com/it/u=588175144,3614862862&fm=253&fmt=auto&app=138&f=JPEG?w=500&h=278',
    name: 'mock 9',
    price: 23,
    likedCount: 34,
}, {
    id: 10,
    imageUrl: 'https://www.9to5carwallpapers.com/wp-content/uploads/2015/04/Porsche-Panamera-Dusky-Hd-Wallpaper.jpg',
    name: 'mock 10',
    price: 23,
    likedCount: 34,
}];

export const MerchantInfo = {
    id: 1,
    logo: "商家logo",
    name: "商家名称",
    introduction: "商家介绍",
    license_image: "https://img1.baidu.com/it/u=1646884743,4131105762&fm=253&fmt=auto&app=138&f=JPEG?w=352&h=350",
    contact: "123455",
    address: "线下地址",
    barter_food_link: "sssssss",
    images: [
        'https://os.alipayobjects.com/rmsportal/QBnOOoLaAfKPirc.png',
        'https://os.alipayobjects.com/rmsportal/QBnOOoLaAfKPirc.png',
        'https://gw.alipayobjects.com/zos/rmsportal/JiqGstEfoWAOHiTxclqi.png',
        'https://os.alipayobjects.com/rmsportal/QBnOOoLaAfKPirc.png',
        'https://os.alipayobjects.com/rmsportal/QBnOOoLaAfKPirc.png',
        'https://gw.alipayobjects.com/zos/rmsportal/JiqGstEfoWAOHiTxclqi.png'
    ],
};
